body {
  height: 100%;
  padding: 0;
  position: relative;
  margin: 0;
}

.static-page {
  box-sizing: border-box;
  padding: 16px;
  height: 100%;
  min-height: calc(
    100vh - var(--main-footer-height)
  ); /* 100% viewport height minus footer height */
}

.static-page .icon {
  display: inline-block;
  background-color: var(--grey-40);
  mask-repeat: no-repeat;
}

.static-page .title {
  color: var(--pink);
  margin: 24px 0;
}

.static-page .title-icon {
  background-color: var(--pink);
  height: 24px;
  width: 24px;
  padding-right: 4px;
  position: relative;
  top: 5px;
}

.static-page h1 {
  color: var(--grey-40);
  margin: 72px 0 40px;
}

.static-page h2 {
  margin: 56px 0 32px;
}

.static-page h3 {
  margin: 24px 0;
}

.static-page h4 {
  margin: 32px 0 16px;
}

.static-page h5 {
  margin: 24px 0 16px;
}

.static-page p {
  margin-bottom: var(--line-height-default);
}

@media (min-width: 768px) {
  .static-page {
    max-width: 960px;
    margin: 0 auto;
  }

  .static-page .title {
    color: var(--pink);
    line-height: 70px;
  }

  .static-page .title-icon {
    margin-right: 32px;
    height: 40px;
    width: 40px;
  }
}
